// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "q_q4 d_bB";
export var storyRowWrapper = "q_ht d_ht d_bH";
export var storyLeftWrapper = "q_q5 d_bx d_bM";
export var storyWrapperFull = "q_q6 d_cB";
export var storyWrapperFullLeft = "q_nL d_cB d_bx d_bM d_bB";
export var contentWrapper = "q_mS d_hv";
export var storyLeftWrapperCenter = "q_q7 d_hw d_bx d_bB d_bM d_bG";
export var storyRightWrapperCenter = "q_q8 d_hB";
export var storyHeader = "q_q9 d_hz d_t d_cq";
export var storyHeaderCenter = "q_hy d_hy d_t d_cq d_dt d_bw";
export var storyParagraphCenter = "q_hx d_hx d_bw d_dt";
export var storyBtnWrapper = "q_rb d_d0 d_dZ d_t d_bx d_bD";
export var storyBtnWrapperCenter = "q_hF d_hF d_dZ d_t d_bx d_bB";
export var imageWrapper = "q_rc d_fd d_X";
export var imageWrapperFull = "q_rd d_t d_F d_bc d_X";