// extracted by mini-css-extract-plugin
export var lbContainerOuter = "t_sV";
export var lbContainerInner = "t_sW";
export var movingForwards = "t_sX";
export var movingForwardsOther = "t_sY";
export var movingBackwards = "t_sZ";
export var movingBackwardsOther = "t_s0";
export var lbImage = "t_s1";
export var lbOtherImage = "t_s2";
export var prevButton = "t_s3";
export var nextButton = "t_s4";
export var closing = "t_s5";
export var appear = "t_s6";