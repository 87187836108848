// extracted by mini-css-extract-plugin
export var articleWrapper = "n_pn";
export var searchResult = "n_p3 n_pn";
export var line = "n_fh d_fh d_cW";
export var articleTitle = "n_p4 d_cn";
export var articleText = "n_pp d_cq";
export var authorImage = "n_ll d_ll d_b5 d_X d_cr";
export var authorText = "n_pQ d_cf d_cr";
export var textCenter = "n_dt d_dt";
export var searchWrapper = "n_p5 d_bx d_bM d_dp d_cj d_X";
export var searchInput = "n_p6 d_cC d_t d_b2";
export var searchBtn = "n_p7 d_bZ d_bz d_bM d_Y d_cB d_dy";
export var text = "n_pZ d_cp";
export var elementWrapper = "n_mQ d_cj";
export var select = "n_p8 d_t d_b2";
export var articleImageWrapper = "n_pG d_cq d_X d_S";
export var articleImageWrapperIcon = "n_pH d_cq d_X d_S";
export var articleImageWrapperColumns = "n_p9 d_cq d_ff d_X";
export var contentPadding = "n_qb d_cQ";
export var otherPadding = "n_qc d_cK";
export var paginationWrapper = "n_qd";
export var pagination = "n_qf";
export var disabledPagination = "n_qg";
export var breakMe = "n_qh";
export var activePage = "n_qj";
export var next = "n_qk";
export var pages = "n_ql";
export var searchTitle = "n_qm d_cp";
export var categoryTitle = "n_qn d_cp d_X";
export var searchText = "n_qp d_cr";
export var bold = "n_qq";
export var field = "n_qr";
export var dateTag = "n_px d_j d_cp d_dn";
export var icon1 = "n_py d_cM";
export var icon2 = "n_pz d_cM d_cG";
export var tagBtn = "n_pB d_bZ d_cB d_dm d_dy";
export var linksWrapper = "n_pT d_cf d_dt";
export var header = "n_pq d_t d_X";
export var headerImage = "n_qs d_bb d_Y d_6 d_5 d_2 d_7";
export var separator = "n_pP d_cW";
export var resultImage = "n_qt";