// extracted by mini-css-extract-plugin
export var alignDiscLeft = "p_qv d_fm d_bD d_ds";
export var alignLeft = "p_qw d_fm d_bD d_ds";
export var alignDiscCenter = "p_qx d_fn d_bB d_dt";
export var alignCenter = "p_bM d_fn d_bB d_dt";
export var alignDiscRight = "p_qy d_fp d_bF d_dv";
export var alignRight = "p_qz d_fp d_bF d_dv";
export var footerContainer = "p_qB d_dT d_bT";
export var footerContainerFull = "p_qC d_dR d_bT";
export var footerHeader = "p_kb d_kb";
export var footerTextWrapper = "p_qD d_t";
export var footerDisclaimerWrapper = "p_kj d_kj d_ch";
export var badgeWrapper = "p_qF d_t d_bx d_bM d_bC d_bG";
export var footerDisclaimerRight = "p_qG undefined";
export var footerDisclaimerLeft = "p_qH undefined";
export var verticalTop = "p_qJ d_bx d_bG d_bL d_bJ";
export var firstWide = "p_qK";
export var disclaimer = "p_qL d_bx d_bG";
export var socialDisclaimer = "p_qM";
export var left = "p_qN";
export var wide = "p_qP d_cw";
export var right = "p_qQ d_bH";
export var line = "p_fh d_fj d_cs";
export var badgeDisclaimer = "p_qR d_bz d_bM d_bG";
export var badgeContainer = "p_qS d_bx d_bF d_bM";
export var badge = "p_qT";
export var padding = "p_qV d_c5";
export var end = "p_qW d_bF";
export var linkWrapper = "p_qX d_dy";
export var link = "p_mW d_dy";
export var colWrapper = "p_qY d_cv";
export var consent = "p_f d_f d_bz d_bM";
export var media = "p_qZ d_bv d_dw";
export var itemRight = "p_q0";
export var itemLeft = "p_q1";
export var itemCenter = "p_q2";
export var exceptionWeight = "p_q3 r_rH";